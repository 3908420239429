import { DateTime } from 'luxon';
import { createContext, useContext, useState } from 'react';
import { signMessage } from '../rpc/signMessage.rpc';
import { authorize, authorizeViaTx, getNonce } from '../apis/auth.api';
import { authTransfer } from '../rpc/authTransfer.rpc';
import { loadState, saveState } from '../utils/localStorage';
import { useWallet } from '@solana/wallet-adapter-react';

const getHash = async nonce => {
  const prefixString = `I am signing my one-time nonce: ${nonce}`;
  const message = new TextEncoder().encode(prefixString);
  return message;
};

const AuthorizationContext = createContext({
  auth: null,
  signIn() {},
  signInViaLedger() {},
  signOut() {},
  signNonce() {},
  isExpired() {},
});

export const useAuth = () => {
  return useContext(AuthorizationContext);
};

const AuthorizationProvider = props => {
  const [auth, setAuth] = useState(loadState());
  const wallet = useWallet();
  const handleSaveAuth = data => {
    saveState(data);
    setAuth(data);
  };

  const signIn = async (walletId = wallet?.publicKey?.toString(), referral) => {
    const expired = DateTime.fromSeconds(auth?.exp ?? 0) < DateTime.utc().plus({ minutes: 150 });
    if (!auth || expired || auth?.username !== walletId) {
      if (!wallet.signMessage || !wallet.publicKey) {
        alert('Cannot sign message, please try again or try another wallet!');
        return;
      }

      const { nonce } = await getNonce(walletId, referral);
      const signature = await wallet.signMessage(await getHash(nonce));
      const authorization = await authorize(walletId, signature);
      handleSaveAuth(authorization);
      return authorization;
    }

    return auth;
  };

  const signInViaLedger = async wallet => {
    const expired = DateTime.fromSeconds(auth?.exp ?? 0) < DateTime.utc().plus({ minutes: 150 });
    if (!auth || expired || auth?.username !== wallet?.publicKey?.toString()) {
      const tx = await authTransfer(wallet);
      const authorization = await authorizeViaTx(wallet?.publicKey?.toString(), tx);
      handleSaveAuth(authorization);
      return authorization;
    }

    return auth;
  };

  const signOut = () => handleSaveAuth(null);

  const signNonce = async () => {
    const walletId = auth?.username;
    const { nonce } = await getNonce(walletId, '');
    const { signature } = await signMessage(nonce);
    return signature;
  };

  const isExpired = () => {
    const expired = DateTime.fromSeconds(auth?.exp ?? 0) < DateTime.utc().plus({ minutes: 150 });
    return !auth || expired;
  };

  return (
    <div>
      <AuthorizationContext.Provider
        value={{ auth, signIn, signInViaLedger, signOut, signNonce, isExpired }}
      >
        {props.children}
      </AuthorizationContext.Provider>
    </div>
  );
};

export { AuthorizationContext, AuthorizationProvider };
