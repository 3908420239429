import { createContext, useReducer } from 'react';
import { loadState, saveState } from '../utils/localStorage';

import nomad from '../data/stories/nomad.story';
import samurai from '../data/stories/samurai.story';
import slime from '../data/stories/slime.story';
import steampunk from '../data/stories/steampunk.story';

const characters = [nomad, samurai, slime, steampunk];

const characterKey = 'character';
const cachedCharacter = loadState(characterKey) ?? null;
console.log(characters[cachedCharacter - 1]);

const initialState = {
  step: cachedCharacter ? 2 : 1,
  character: cachedCharacter ? characters[cachedCharacter - 1] : null,
  mode: 'DEGEN',
  page: null,
};

const reducer = (wizardState, action) => {
  switch (action.type) {
    case 'SET_STEP':
      return { ...wizardState, step: action.step };
    case 'SET_CHARACTER':
      saveState(action.character.id, characterKey);
      return { ...wizardState, character: action.character };
    case 'SET_PAGE':
      return { ...wizardState, page: action.page };
    default:
      return wizardState;
  }
};

const WizardContext = createContext();

const WizardProvider = ({ children }) => {
  const [wizardState, dispatch] = useReducer(reducer, initialState);
  return (
    <WizardContext.Provider value={{ wizardState, dispatch }}>{children}</WizardContext.Provider>
  );
};

export { WizardContext, WizardProvider };
