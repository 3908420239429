const story1 = `
Title: The Psychedelic Chronicles of Slime Fat Cat: The Slime Seeker's Voyage
Style: Mystical
Tone: Playful
Prologue:

In a realm where the legacy of the Degen Fat Cats has transcended the boundaries of time and space, the spirit of adventure has given rise to countless heroes, each proudly bearing the title of Degen Fat Cat. As the generations unfold, the descendants of the ancient lineage continue to explore new frontiers, leaving their mark on the fabric of reality.

In a corner of the cosmos where reality bends and shimmers, we find ourselves in the midst of glowing purple clouds that envelop a mysterious mountain. The landscape is adorned with graffiti-inspired illustrations, breathing life into the emerald and amber hues that cascade across intricate psychedelic vistas. It is within this surreal world that we encounter Slime Fat Cat, a Slime Degen Fat Cat who embodies the essence of his surroundings.

Born from the fusion of cosmic energies and the slime of creation, Slime Fat Cat is a creature of the cosmos, a living testament to the wonders that lay hidden within the depths of the universe. With detailed character illustrations and the pulsating glow of bioluminescent lights, he is a beacon of curiosity and intrigue, eager to uncover the mysteries that surround him.

As our Slime Degen Fat Cat ventures forth into the vast expanse of the psychedelic landscape, he will traverse the peaks and valleys of dark cyan and green, encountering a myriad of strange and captivating creatures. The world around him is a canvas of large, expressive scenes that defy logic and beckon the imagination.

Throughout his journey, Slime Fat Cat will face challenges and revelations that will test the very essence of his being. With each step, he delves deeper into the heart of the psychedelic realm, uncovering the secrets of the glowing purple clouds and forging bonds with the extraordinary beings that call this world home.

Will Slime Fat Cat unlock the mysteries of the cosmic mountain and uncover the truth of his origins? What wonders and perils lie hidden within the psychedelic landscape, and how will his journey shape the destiny of the Degen Fat Cat lineage? Embrace the unknown and embark on an unforgettable voyage, as we follow the exploits of Slime Fat Cat on his quest to unravel the cosmic enigma that surrounds him.

`;

const story2 = `
Title: The Kaleidoscopic Quest of Slime Fat Cat: The Slime Seeker's Dreamy Adventure
Style: Mystical
Tone: Playful
Prologue:

In a cosmos where the Degen Fat Cat bloodline has stretched its influence across countless dimensions, the spirit of exploration has given birth to a multitude of heroes, each proudly bearing the title of Degen Fat Cat. With each new generation, the descendants of this ancient lineage continue to delve into uncharted territories, unraveling the mysteries of existence.

In a world where the boundaries between dreams and reality intertwine, we find ourselves amidst glowing purple clouds that blanket a majestic mountain. At its base, a mesmerizing waterfall cascades into a crystalline pool, framed by a vibrant, colorful tree that seems to defy the very laws of nature. It is within this enchanting landscape that we meet Slime Fat Cat, a Slime Degen Fat Cat who epitomizes the essence of his fantastical surroundings.

Crafted from the building blocks of the cosmos and the primordial ooze, Slime Fat Cat is a being born of curiosity and wonder. With detailed character illustrations and the ethereal glow of bioluminescent lights, he stands as a symbol of imagination and discovery, eager to unveil the hidden secrets of this magical world.

As our Slime Degen Fat Cat embarks on his journey, he navigates the intricate psychedelic landscapes, adorned with graffiti-inspired illustrations that breathe life into the emerald and amber hues. The world around him is a canvas of large, spellbinding scenes that captivate the mind and awaken the spirit.

Throughout his odyssey, Slime Fat Cat will face trials and revelations that challenge the core of his being. With every step, he ventures deeper into the heart of this dreamy realm, uncovering the enigmas of the glowing purple clouds and forging alliances with the extraordinary entities that inhabit this realm.

Will Slime Fat Cat unlock the mysteries of the ethereal mountain and unearth the truth of his origins? What wonders and perils lie hidden within the captivating landscape, and how will his journey shape the destiny of the Degen Fat Cat lineage? Embrace the wonders of the imagination and embark on an unforgettable expedition, as we follow the exploits of Slime Fat Cat on his quest to unravel the cosmic enigma that shrouds him.

`;

const story = {
  id: 3,
  pfp: '/images/cats/15095.png',
  name: 'Slime Fat Cat',
  stories: [story1, story2],
  backgrounds: [
    '/images/backgrounds/slime_1.jpg',
    '/images/backgrounds/slime_2.jpg',
    '/images/backgrounds/slime_3.jpg',
  ],
};
export default story;
