const story1 = `
Title: The Aerial Chronicles of Steampunk Fat Cat: The Steampunk Skyward Voyage
Style: Creative
Tone: Ironic
Prologue:

In a universe where the legacy of the Degen Fat Cats has spanned across infinite realms and dimensions, the spirit of adventure has birthed countless heroes, each proudly wearing the title of Degen Fat Cat. As new generations unfold, the descendants of this ancient lineage continue to venture into unknown territories, exploring the wonders that lie beyond the edge of the known world.

In a realm where the skies are painted with the hues of dreams and imagination, we find ourselves gazing upon a sprawling fantasy city, adorned with intricate steampunk architecture and technology. Futuristic Victorian buildings stretch into the heavens, punctuated by the ever-present hum of ingenious machinery. Above this magnificent metropolis, hot air balloons dot the skyline, carrying passengers and cargo between the towering spires.

It is within this captivating world that we encounter Steampunk Fat Cat, a Steampunk Degen Fat Cat who embodies the essence of his awe-inspiring surroundings. Equipped with an array of fascinating gadgets and gizmos, he stands as a testament to the ingenuity and creativity of his people.

As our Steampunk Degen Fat Cat sets forth on his journey, he takes to the skies, navigating the cityscape aboard his custom-built airship. The world around him is a marvel of exotic landscapes and technological wonders, each meticulously crafted to blend seamlessly with the environment.

Throughout his expedition, Steampunk Fat Cat will face challenges and revelations that test the limits of his intellect and resolve. With each new adventure, he delves deeper into the heart of the steampunk realm, uncovering hidden secrets and forging alliances with the extraordinary beings who call this city home.

Will Steampunk Fat Cat unlock the mysteries of the fantastical city and discover the true potential of steampunk technology? What wonders and dangers await him within the lofty towers and labyrinthine streets, and how will his journey shape the future of the Degen Fat Cat lineage? Embrace the thrill of exploration and embark on an unforgettable adventure, as we follow the exploits of Steampunk Fat Cat on his quest to conquer the skies and unravel the enigma of the steampunk realm.

`;

const story2 = `
Title: The Aerial Escapades of Steampunk Fat Cat: Steampunk Skies and Unseen Wonders
Style: Creative
Tone: Ironic
Prologue:

In a world where the boundaries of technology and magic blur, the long lineage of Degen Fat Cats continues to forge its path through history. Steampunk Fat Cat, a pioneer of the skies and a master of steampunk ingenuity, embarks on a breathtaking journey high above the ground, exploring the vast expanses of a realm where the wonders of the past meet the innovations of the future.

Our tale begins in a bustling fantasy city, an architectural marvel that defies gravity and imagination, its streets teeming with steam-powered machines, clockwork automatons, and exotic creatures from far-off lands. Here, amid the intricate steampunk tapestry of this futuristic Victorian metropolis, hot air balloons and airships dot the sky, their passengers eager to explore the mysteries that lie beyond the city's towering spires.

As Steampunk Fat Cat takes to the skies in his custom-built airship, he embarks on a thrilling adventure filled with enigmatic discoveries, uncharted islands, and secret societies hidden among the clouds. Guided by his innate curiosity and unyielding spirit, our intrepid hero encounters a diverse array of characters, each with their own tales to tell and knowledge to share.

But as Steampunk Fat Cat delves deeper into the heart of this airborne world, he uncovers a sinister plot that threatens the very fabric of reality. With time running out and the fate of the skies hanging in the balance, our hero must rely on his wits, his cunning, and his trusty steampunk gadgets to unravel the conspiracy and save the realm from an unimaginable doom.

Prepare to embark on an unforgettable journey filled with intrigue, wonder, and high-flying adventure as Steampunk Fat Cat takes to the skies, navigating a realm where the impossible becomes reality and the limits of the imagination are redefined. Witness the unfolding of a grand steampunk epic that will leave you breathless and longing for more.
`;

const story = {
  id: 4,
  pfp: '/images/cats/8338.png',
  name: 'Steampunk Degen Fat Cat',
  stories: [story1, story2],
  backgrounds: [
    '/images/backgrounds/steampunk_1.jpg',
    '/images/backgrounds/steampunk_2.jpg',
    '/images/backgrounds/steampunk_3.jpg',
    '/images/backgrounds/steampunk_4.jpg',
    '/images/backgrounds/steampunk_5.jpg',
    '/images/backgrounds/steampunk_6.jpg',
    '/images/backgrounds/steampunk_7.jpg',
    '/images/backgrounds/steampunk_8.jpg',
    '/images/backgrounds/steampunk_9.jpg',
  ],
};
export default story;
