import React from 'react';
import { Table, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../utils/helpers';

const CampaignList = ({ campaigns }) => {
  const navigate = useNavigate();

  const handleClick = id => {
    navigate(`/campaigns/${id}`);
  };

  const renderStatusBadge = expireAt => {
    const isExpired = new Date(expireAt) < new Date();

    return <Badge bg={isExpired ? 'danger' : 'success'}>{isExpired ? 'Expired' : 'Active'}</Badge>;
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {/* <th>ID</th> */}
          <th>Name</th>
          <th>Game ID</th>
          <th>Expiration Date</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {campaigns.map(campaign => (
          <tr
            key={campaign.id}
            onClick={() => handleClick(campaign.id)}
            style={{ cursor: 'pointer' }}
          >
            {/* <td>{campaign.id}</td> */}
            <td>{campaign.name}</td>
            <td>{campaign.gameId}</td>
            <td>{formatDate(campaign.expireAt)}</td>
            <td>{renderStatusBadge(campaign.expireAt)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CampaignList;
