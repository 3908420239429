import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CampaignBreadcrumb = ({ currentName }) => {
  return (
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/campaigns' }}>
        Campaigns
      </Breadcrumb.Item>
      <Breadcrumb.Item active>{currentName ? currentName : `Risk Free Plays`}</Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default CampaignBreadcrumb;
