import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createPromoShopItem, getPromoById, getPromoShopItems } from '../../../apis/atlantis.api';
import { useAuth } from '../../../contexts/auth.context';
import ExpandableShopItem from './ShopItem';
import AddShopItemButton from './AddShopItemButton';
import PromoBreadcrumb from './PromoBreadcrumb';
import { formatDate, getPromoTypeColor, getStatusColor } from '../../terminal/handlers/utils';
import { PromoType } from '../../../utils/constants';
import { Button, Spinner } from 'react-bootstrap';

const Promo = () => {
  const navigate = useNavigate();

  const [promo, setPromo] = useState(null);
  const [shopItems, setShopItems] = useState([]);
  const { promoId } = useParams();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);

  const authToken = useRef(auth?.idToken); // Create a ref for the auth?.idToken

  useEffect(() => {
    authToken.current = auth?.idToken; // Update the ref with the latest value of auth?.idToken
  }, [auth?.idToken]);

  useEffect(() => {
    const fetchPromo = async () => {
      try {
        const promoData = await getPromoById(promoId, authToken.current);
        setPromo(promoData);
      } catch (error) {
        console.error('Error fetching promo data:', error);
      }
    };

    fetchPromo();
  }, [promoId]);

  const fetchPromoShopItems = useCallback(async () => {
    setLoading(true);
    try {
      const items = await getPromoShopItems(promoId, authToken.current);
      setShopItems(items);
    } catch (error) {
      console.error('Error fetching promo shop items:', error);
    }
    setLoading(false);
  }, [promoId]);

  useEffect(() => {
    fetchPromoShopItems();
    if ([PromoType.FRAGMENT, PromoType.LOSER].includes(promo?.promoType)) {
      fetchPromoShopItems();
    }
  }, [promo, fetchPromoShopItems]);

  const handleRefresh = async () => {
    setLoading(true);
    await fetchPromoShopItems();
    setLoading(false);
  };

  const handleAddShopItem = async newShopItem => {
    newShopItem.promoId = promo.promoId;
    const newItem = await createPromoShopItem(promo.promoId, newShopItem, authToken.current);
    setShopItems([...shopItems, newItem]);
  };

  const LoadingSpinner = () => (
    <Spinner animation="border" size="sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );

  return (
    <div className="container pt-3" style={{ overflow: 'scroll' }}>
      {promo ? (
        <div>
          <PromoBreadcrumb currentName={promo?.promoName} promoId={promo.promoId} />
          <div className="my-4">
            <h2>Promo: {promo.promoName}</h2>
            <span className={`badge me-2 ${getPromoTypeColor(promo.promoType)}`}>
              {promo.promoType}
            </span>
            <span className={`badge ${getStatusColor(promo.status)}`}>{promo.status}</span>
            <div className="my-2">
              {formatDate(promo.startTime)} - {formatDate(promo.endTime)}
            </div>
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
      {promo && [PromoType.FRAGMENT, PromoType.LOSER].includes(promo.promoType) && (
        <>
          <div className="d-flex justify-content-between mb-2">
            <h4>Shop Items</h4>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-dark me-2"
                onClick={() => navigate(`/promos/${promoId}/search`)}
              >
                <i className="fa-solid fa-search" /> Search Wallet
              </button>
              <AddShopItemButton onAdd={handleAddShopItem} />
              <Button
                variant="secondary"
                className="mx-2"
                onClick={handleRefresh}
                disabled={loading}
              >
                {loading ? <LoadingSpinner /> : 'Refresh'}
              </Button>
            </div>
          </div>

          {shopItems.length > 0 ? (
            <div>
              {shopItems.map(item => (
                <ExpandableShopItem
                  key={item.itemId}
                  shopItem={item}
                  token={auth?.idToken}
                  refreshShopItems={fetchPromoShopItems}
                />
              ))}
            </div>
          ) : (
            <LoadingSpinner />
          )}
        </>
      )}
    </div>
  );
};

export default Promo;
