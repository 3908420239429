import React, { useState, useEffect } from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import ShopItemDetailsForm from './ShopItemDetailsForm';
import ShopItemRewardsForm from './ShopItemRewardsForm';

const EditShopItemModal = ({ open, handleClose, shopItem, rewards, token, fetchRewards }) => {
  const [activeTab, setActiveTab] = useState('details');
  const [editedShopItem, setEditedShopItem] = useState(shopItem);

  useEffect(() => {
    setEditedShopItem(shopItem);
  }, [shopItem]);

  return (
    <Modal show={open} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Edit Shop Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs id="edit-shop-item-tabs" activeKey={activeTab} onSelect={k => setActiveTab(k)}>
          <Tab eventKey="details" title="Edit Shop Item Details">
            <ShopItemDetailsForm
              shopItem={editedShopItem}
              token={token}
              handleClose={handleClose}
            />
          </Tab>
          <Tab eventKey="rewards" title="Edit Shop Item Rewards">
            <ShopItemRewardsForm
              rewards={rewards}
              fetchRewards={fetchRewards}
              promoId={shopItem.promoId}
              itemId={shopItem.itemId}
              token={token}
              handleClose={handleClose}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default EditShopItemModal;
