import { DateTime } from 'luxon';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate, getPromoTypeColor, getStatusColor } from '../../terminal/handlers/utils';

const PromoList = ({ cardItems }) => {
  const navigate = useNavigate();

  const sortedCardItems = cardItems
    .slice()
    .sort((a, b) => DateTime.fromISO(b.startTime) - DateTime.fromISO(a.startTime));

  const handleRowClick = promoId => {
    navigate(`/promos/${promoId}`);
  };

  return (
    <div className="container">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Promo Name</th>
            <th>Promo Type</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {sortedCardItems.map((card, index) => (
            <tr key={index} className="table-row" onClick={() => handleRowClick(card.promoId)}>
              <td>{card.promoName}</td>
              <td>
                <span className={`badge me-2 ${getPromoTypeColor(card.promoType)}`}>
                  {card.promoType}
                </span>
              </td>
              <td>{formatDate(card.startTime)}</td>
              <td>{formatDate(card.endTime)}</td>
              <td>
                <span className={`badge ${getStatusColor(card.status)}`}>{card.status}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PromoList;
