import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
const twoWeeksFromNow = DateTime.utc()
  .setZone(USER_TIMEZONE)
  .plus({ weeks: 2 })
  .toFormat(`yyyy-MM-dd'T'HH':'mm`);

const CreateCampaignModal = ({ show, onHide, onCreate }) => {
  const [name, setName] = useState('');
  const [expireAt, setExpireAt] = useState(twoWeeksFromNow);

  const handleSubmit = async () => {
    await onCreate({ name, expireAt });
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Campaign Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter campaign name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Expiration Date</Form.Label>
            <Form.Control
              type="datetime-local"
              defaultValue={expireAt}
              onChange={e => setExpireAt(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Create Campaign
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateCampaignModal;
