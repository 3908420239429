import { get, post } from './client';

const BASE_URL = process.env.REACT_APP_AEGIS_URL ?? 'https://dev-api.degenaegis.com/v1';

export const getCampaigns = async () => {
  const res = await get(`${BASE_URL}/campaigns`);
  return res?.payload;
};

export const getCampaign = async campaignId => {
  const res = await get(`${BASE_URL}/campaigns/${campaignId}`);
  return res?.payload;
};

export const createCampaign = async (body, Authorization = '') => {
  const res = await post(`${BASE_URL}/campaigns`, { ...body }, { Authorization });
  return res?.data?.payload;
};

export const getRiskFreePlays = async (campaignId, Authorization = '') => {
  const res = await get(`${BASE_URL}/campaigns/${campaignId}/risk-free-plays`, { Authorization });
  return res?.payload;
};

export const createRiskFreePlays = async (campaignId, body, Authorization = '') => {
  const res = await post(
    `${BASE_URL}/campaigns/${campaignId}/risk-free-plays`,
    { ...body },
    { Authorization }
  );
  return res?.data?.payload;
};

export const getRiskFreePlaysOfWallet = async (walletId, Authorization = '') => {
  const res = await get(`${BASE_URL}/risk-free-plays?walletId=${walletId}&status=ALL`, {
    Authorization,
  });
  return res?.payload;
};

export const reprocessRiskFreePlays = async (campaignId, rfpId, Authorization = '') => {
  const res = await post(
    `${BASE_URL}/campaigns/${campaignId}/risk-free-plays/${rfpId}/reprocess`,
    {},
    { Authorization }
  );
  return res?.data?.payload;
};
