import { get, post } from './client';

// const BASE_URL = 'http://localhost:3000';
const BASE_URL = process.env.REACT_APP_API_URL ?? 'https://dev-api.degencoinflip.com/v2';

export const getNonce = async (walletId, referral) => {
  let url = `${BASE_URL}/wallets/${walletId}/nonce?x=gn`;
  if (referral?.length) {
    url = url + `&referral=${referral}`;
  }
  const response = await get(url);
  return response?.payload;
};

export const authorize = async (walletId, signature) => {
  const url = `${BASE_URL}/authorize`;
  const { data } = await post(url, { walletId, signature });
  return data?.payload;
};

export const authorizeViaTx = async (walletId, signature) => {
  const url = `${BASE_URL}/authorize/${walletId}`;
  const { data } = await post(url, { signature });
  return data?.payload;
};
