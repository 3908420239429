import { DateTime } from 'luxon';

export const formatDate = (dateString, format = DateTime.DATETIME_FULL) => {
  const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = DateTime.fromISO(dateString, { zone: 'utc' });
  return date.setZone(USER_TIMEZONE).toLocaleString(format);
};

export const getStatusColor = status => {
  return status === 'ACTIVE' ? 'bg-success' : 'bg-danger';
};

export const getPromoTypeColor = promoType => {
  return promoType === 'FRAGMENT' ? 'bg-primary' : 'bg-warning';
};

export const customRound = amount => {
  // Check the fractional part of the product
  const fractionalPart = amount % 1;

  // Decide whether to add an epsilon to handle the 0.999 case
  const epsilon = fractionalPart >= 0.999 ? 0.0005 : 0;

  // Compute and return the rounded result
  const roundedProduct = Math.round((amount + epsilon) * 1000) / 1000;

  return roundedProduct;
};
