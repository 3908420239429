import { DateTime } from 'luxon';

export function getRandom(arr) {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

export function storyParser(text) {
  const choices = ['A', 'B', 'C'];
  const [story, ...optionTexts] = text.split(/A:|B:|C:/);
  const optionsWithLetter = optionTexts.map(
    (optionText, i) => `${choices[i]}: ${optionText.trim()}`
  );
  const options = optionTexts.map(optionText => optionText.trim());
  return {
    story: story.trim(),
    options,
    optionsWithLetter,
  };
}

export function isValidAmount(num) {
  if (isNaN(num) || num < 0.01 || num > 10) {
    return false;
  }

  return true;
}

export const formatDate = dateString => {
  const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = DateTime.fromISO(dateString, { zone: 'utc' });
  return date.setZone(USER_TIMEZONE).toLocaleString(DateTime.DATETIME_FULL);
};

export const getStatusColor = status => {
  return status === 'ACTIVE' ? 'bg-success' : 'bg-danger';
};

export const getPromoTypeColor = promoType => {
  return promoType === 'FRAGMENT'
    ? 'bg-primary'
    : promoType === 'RAFFLE'
    ? 'bg-warning'
    : promoType === 'LOSER'
    ? 'bg-dark'
    : promoType === 'SCROLL'
    ? 'bg-danger'
    : 'bg-warning';
};
