import { useWallet } from '@solana/wallet-adapter-react';
import React, { useContext, useEffect, useState } from 'react';
import { AuthorizationContext } from '../../contexts/auth.context';
import { WizardContext } from '../../contexts/story.context';
import { Pages } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';

const SelectionPage = () => {
  const wallet = useWallet();
  const { signIn } = useContext(AuthorizationContext);
  const { dispatch } = useContext(WizardContext);
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (wallet) {
      wallet.connect();
    }
  }, [wallet]);

  const selectPage = async character => {
    if (loading) return;

    setLoading(true);
    setSelected(character);
    try {
      await signIn();
      dispatch({ type: 'SET_CHARACTER', character });
      dispatch({ type: 'SET_STEP', step: 2 });
      navigate('/promos');
    } catch (e) {
      console.log(e);
      setSelected(null);
    }

    setLoading(false);
  };

  return (
    <div className="container-fluid">
      <div className="min-vh-100 bg-100 row">
        <div className="align-self-center mx-auto col-xl-3 col-md-4 col-sm-10 col-12 container-fluid p-4 d-flex-column">
          <div className="row">
            {Pages.map((page, index) => (
              <div className="col-6 mb-3" key={index}>
                <div className="card-select-image" onClick={() => selectPage(page)}>
                  <div
                    className={'card-img w-100  ' + (selected === page ? 'card-img-selected' : '')}
                  >
                    <img className="img-fluid" src={page.selectImageUrl} alt={page?.name}></img>
                    <img
                      src={`https://www.notion.so/images/onboarding/${
                        selected === page ? 'checked' : 'unchecked'
                      }.svg`}
                      style={{
                        position: 'absolute',
                        top: '12px',
                        right: '12px',
                        height: '24px',
                        width: '24px',
                      }}
                      alt="checked-icon"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="w-100 d-flex justify-content-center pt-5">
            {loading ? <i className="fa-solid fa-circle-notch fa-spin fa-2xl"></i> : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectionPage;
