const getProvider = () => {
  // Check if Solana is available in the window object
  if ('solana' in window) {
    const anyWindow = window;
    const provider = anyWindow.solana;
    // Check if the provider is Phantom and connected
    if (isConnected(provider, 'phantom')) {
      return provider;
    }
  }

  // Check if Solflare is available in the window object
  if ('solflare' in window) {
    const anyWindow = window;
    const provider = anyWindow.solflare;
    // Check if the provider is Solflare and connected
    if (isConnected(provider, 'solflare')) {
      return provider;
    }
  }

  // No compatible wallet provider was found, so display an error message
  alert('No compatible wallet provider was found.');
};

// Checks if a wallet provider is connected and matches the specified type
const isConnected = (provider, type) => {
  return provider.isConnected && provider.is[type];
};

// Signs a message using the connected wallet provider
export const signMessage = async nonce => {
  const message = `I am signing my one-time nonce: ${nonce}`;
  const provider = getProvider();
  const data = new (window.TextEncoder || TextEncoder)().encode(message);
  try {
    const signedMessage = await provider.signMessage(data);
    return signedMessage;
  } catch (err) {
    console.warn(err);
  }
};
