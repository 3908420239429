import { get, post, del, put } from './client';

const BASE_URL = process.env.REACT_APP_ATLANTIS_URL ?? 'https://dev-api.degentreasures.com/v1';

export const getPromos = async (Authorization = '') => {
  const res = await get(`${BASE_URL}/promos`, {
    Authorization,
  });
  return res?.payload;
};

export const getPromoById = async (promoId, Authorization = '') => {
  const res = await get(`${BASE_URL}/promos/${promoId}`, {
    Authorization,
  });
  return res?.payload;
};

export const createPromo = async (body, Authorization = '') => {
  const res = await post(`${BASE_URL}/promos`, { ...body }, { Authorization });
  return res?.data?.payload;
};

export const getPromoShopItems = async (promoId, Authorization = '') => {
  const res = await get(`${BASE_URL}/promos/${promoId}/items`, {
    Authorization,
  });
  return res?.payload;
};

export const getPromoShopItemsOfWallet = async (promoId, walletId, Authorization = '') => {
  const res = await get(`${BASE_URL}/wallets/${walletId}/promos/${promoId}/items?filter=NONE`, {
    Authorization,
  });
  return res?.payload;
};

export const createPromoShopItem = async (promoId, body, Authorization = '') => {
  const res = await post(`${BASE_URL}/promos/${promoId}/items`, { ...body }, { Authorization });
  return res?.data?.payload;
};

export const deletePromoShopItem = async (promoId, itemId, Authorization = '') => {
  const res = await del(`${BASE_URL}/promos/${promoId}/items/${itemId}`, { Authorization });
  return res?.data?.payload;
};

export const updatePromoShopItem = async (promoId, itemId, body, Authorization = '') => {
  const res = await put(
    `${BASE_URL}/promos/${promoId}/items/${itemId}`,
    { ...body },
    { Authorization }
  );
  return res?.payload;
};

export const getRewardsOfItem = async (promoId, itemId, Authorization = '') => {
  const res = await get(`${BASE_URL}/promos/${promoId}/items/${itemId}/rewards`, {
    Authorization,
  });
  return res?.payload;
};

export const updateRewardsOfItem = async (promoId, itemId, body, Authorization = '') => {
  const res = await post(
    `${BASE_URL}/promos/${promoId}/items/${itemId}/rewards`,
    { ...body },
    { Authorization }
  );
  return res?.payload;
};

export const getRewardedBoxes = async (promoId, rewardId, Authorization = '') => {
  const res = await get(`${BASE_URL}/promos/${promoId}/rewards/${rewardId}/boxes`, {
    Authorization,
  });
  return res?.payload;
};

export const getBoxesByItem = async (promoId, itemId, Authorization = '') => {
  const res = await get(`${BASE_URL}/promos/${promoId}/items/${itemId}/boxes`, {
    Authorization,
  });
  return res?.payload;
};
