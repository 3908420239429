import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CreatePromoModal = ({ show, onHide, onCreate }) => {
  const [promoName, setPromoName] = useState('');
  const [communityId, setCommunityId] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [promoType, setPromoType] = useState('RAFFLE');
  const [dateError, setDateError] = useState('');

  const validateDates = () => {
    if (endTime <= startTime) {
      setDateError('End time must be after start time.');
      return false;
    } else {
      setDateError('');
      return true;
    }
  };

  const handleSubmit = async () => {
    if (!validateDates()) return;
    await onCreate({
      promoName,
      communityId,
      startTime,
      endTime,
      scope: 'GLOBAL',
      promoType,
    });
    onHide();
  };
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Promo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Promo Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter promo name"
              value={promoName}
              onChange={e => setPromoName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Community</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Community Id"
              value={communityId}
              onChange={e => setCommunityId(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Start Time</Form.Label>
            <Form.Control
              type="datetime-local"
              value={startTime}
              onChange={e => {
                setStartTime(e.target.value);
                validateDates();
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>End Time</Form.Label>
            <Form.Control
              type="datetime-local"
              value={endTime}
              onChange={e => {
                setEndTime(e.target.value);
                validateDates();
              }}
            />
            {dateError && <div className="text-danger">{dateError}</div>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Promo Type</Form.Label>
            <div>
              <Form.Check
                type="radio"
                id="raffleRadio"
                label="Raffle"
                name="promoType"
                value="RAFFLE"
                checked={promoType === 'RAFFLE'}
                onChange={e => setPromoType(e.target.value)}
              />
              <Form.Check
                type="radio"
                id="fragmentRadio"
                label="Fragment"
                name="promoType"
                value="FRAGMENT"
                checked={promoType === 'FRAGMENT'}
                onChange={e => setPromoType(e.target.value)}
              />
              <Form.Check
                type="radio"
                id="scrollRadio"
                label="Scroll"
                name="promoType"
                value="SCROLL"
                checked={promoType === 'SCROLL'}
                onChange={e => setPromoType(e.target.value)}
              />
              <Form.Check
                type="radio"
                id="loserRadio"
                label="Loser"
                name="promoType"
                value="LOSER"
                checked={promoType === 'LOSER'}
                onChange={e => setPromoType(e.target.value)}
              />
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Create Promo
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreatePromoModal;
