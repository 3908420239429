import React, { useEffect, useState, useCallback } from 'react';
import { Button, Card, Collapse, Container, Row, Col, Spinner } from 'react-bootstrap';
import {
  getRewardsOfItem,
  deletePromoShopItem,
  getRewardedBoxes,
  getBoxesByItem,
} from '../../../apis/atlantis.api';
import EditShopItemModal from './ShopItemModal';
import ExportCSVButton from '../../shared-components/ExportButton';

const RewardItem = ({ reward, token }) => {
  const [rewardedBoxes, setRewardedBoxes] = useState();

  const handleGetRewardedBoxes = async () => {
    const payload = await getRewardedBoxes(reward.promoId, reward.rewardId, token);
    setRewardedBoxes(payload);
  };
  return (
    <Row className="mb-3">
      <Col xs={12} md={3} className="text-center">
        <img
          src={reward.imageUrl}
          alt={reward.name}
          style={{
            width: '100%',
            maxWidth: '100px',
            maxHeight: '100px',
            objectFit: 'contain',
          }}
          className="mb-2 mb-md-0"
        />
      </Col>
      <Col xs={12} md={6}>
        <p className="mb-0">
          <strong>{reward.name}</strong>
        </p>
        <p className="mb-0">Reward Type: {reward.rewardType}</p>
        <p className="mb-0">Chance: {(reward.pct * 100).toFixed(2)}%</p>
      </Col>
      <Col xs={12} md={3} className="text-center">
        {reward.rewardType === 'SOL' && <p className="mb-0">Amount: {reward.solAmount} SOL</p>}
        {reward.rewardType === 'NFT' && <p className="mb-0">Quantity: {reward.quantity}</p>}
        {rewardedBoxes?.length ?? (
          <Button variant="success" onClick={handleGetRewardedBoxes} className="mt-2 btn-sm">
            Load
          </Button>
        )}
        {rewardedBoxes?.length && (
          <>
            <br />
            Export Rewarded: <ExportCSVButton data={rewardedBoxes} number={rewardedBoxes?.length} />
          </>
        )}
      </Col>
    </Row>
  );
};

const ExpandableShopItem = ({ shopItem, token, refreshShopItems }) => {
  const [showModal, setShowModal] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [purchasedBoxes, setPurchasedBoxes] = useState();

  const handleGetPurchasedBoxesByItem = async () => {
    setLoading(true);
    try {
      const payload = await getBoxesByItem(shopItem.promoId, shopItem.itemId, token);
      setPurchasedBoxes(payload);
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  const fetchRewards = useCallback(async () => {
    try {
      const rewardsList = await getRewardsOfItem(shopItem.promoId, shopItem.itemId, token);
      setRewards(rewardsList);
    } catch (error) {
      console.error('Error fetching rewards:', error);
    }
  }, [shopItem.promoId, shopItem.itemId, token]);

  useEffect(() => {
    fetchRewards();
  }, [fetchRewards]);

  const handleClose = async () => {
    setShowModal(false);
  };
  const handleShow = () => setShowModal(true);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const deleteShopItem = async item => {
    if (window.confirm(`Are you sure you want to delete ${item.name}?`)) {
      try {
        setLoading(true);
        await deletePromoShopItem(item.promoId, item.itemId, token);
        refreshShopItems();
        setLoading(false);
      } catch (error) {
        console.error('Error deleting shop item:', error);
      }
    }
  };

  return (
    <div className="shop-item mb-3">
      <Card className="w-100">
        <Card.Body>
          <Container>
            <Row>
              <Col xs={12} md={3} className="text-center">
                <Card.Img
                  src={shopItem.imageUrl}
                  alt={shopItem.name}
                  style={{
                    width: '100%',
                    maxWidth: '150px',
                    maxHeight: '150px',
                    objectFit: 'contain',
                  }}
                  className="mb-2 mb-md-0"
                />
              </Col>
              <Col xs={12} md={6}>
                <Card.Title>{shopItem.name}</Card.Title>
                <Card.Text>{shopItem.description}</Card.Text>
              </Col>
              <Col xs={12} md={3} className="text-center">
                <Button variant="primary" onClick={handleShow} className="mt-2">
                  Edit
                </Button>
                {!purchasedBoxes?.length && (
                  <Button
                    variant="success"
                    onClick={handleGetPurchasedBoxesByItem}
                    className="mt-2 ms-2"
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      'Load'
                    )}
                  </Button>
                )}
                {!!purchasedBoxes?.length && (
                  <ExportCSVButton
                    className="mt-2 ms-2"
                    data={purchasedBoxes}
                    number={purchasedBoxes?.length}
                  />
                )}
                <Button
                  variant="danger"
                  onClick={() => deleteShopItem(shopItem)}
                  className="mt-2 ms-2"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    'Delete'
                  )}
                </Button>
                <Button variant="secondary" onClick={toggleExpanded} className="mt-2 ms-2">
                  Expand
                </Button>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>

      <Collapse in={expanded}>
        <div>
          <Container>
            {rewards.map((reward, index) => (
              <RewardItem reward={reward} key={index} token={token} />
            ))}
          </Container>
        </div>
      </Collapse>
      <EditShopItemModal
        open={showModal}
        handleClose={handleClose}
        fetchRewards={fetchRewards}
        shopItem={shopItem}
        rewards={rewards}
        token={token}
      />
    </div>
  );
};

export default ExpandableShopItem;
