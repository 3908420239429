import React, { useState, useEffect, useRef } from 'react';
import CampaignList from './CampaignList';
import CreateCampaignModal from './CreateCampaignModal';
import { createCampaign, getCampaigns } from '../../../apis/campaigns.api';
import { useAuth } from '../../../contexts/auth.context';
import { useNavigate } from 'react-router-dom';

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const { auth } = useAuth();
  const authToken = useRef(auth?.idToken);

  useEffect(() => {
    authToken.current = auth?.idToken;
  }, [auth?.idToken]);

  useEffect(() => {
    // Replace this with the API call to fetch the list of campaigns
    const fetchData = async () => {
      const response = await getCampaigns();
      setCampaigns(response);
    };

    fetchData();
  }, []);

  const handleCreateCampaign = async campaign => {
    // Replace this with the API call to create a new campaign
    const newCampaign = await createCampaign(campaign, authToken.current);

    if (newCampaign) {
      setCampaigns([newCampaign, ...campaigns]);
      navigate(`/campaigns/${newCampaign.id}`);
    }
  };

  return (
    <div className="container pt-3" style={{ overflow: 'scroll' }}>
      <h1 className="my-4">Campaigns</h1>
      <button type="button" className="btn btn-primary mb-4" onClick={() => setShowModal(true)}>
        Create Campaign
      </button>
      <button
        type="button"
        className="btn btn-secondary ms-2 mb-4"
        onClick={() => navigate(`/campaigns/search`)}
      >
        <i className="fa-solid fa-search" /> Search
      </button>
      <CampaignList campaigns={campaigns} />
      <CreateCampaignModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onCreate={handleCreateCampaign}
      />
    </div>
  );
};

export default Campaigns;
