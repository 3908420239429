import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { updatePromoShopItem } from '../../../apis/atlantis.api';

const ShopItemDetailsForm = ({ shopItem, onChange, token, handleClose }) => {
  const [formValues, setFormValues] = useState(shopItem);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormValues(shopItem);
  }, [shopItem]);

  const handleChange = event => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    onChange({ ...formValues, [name]: value });
  };

  const handleSubmit = async formValues => {
    setLoading(true);
    const itemDetails = {
      cost: formValues.cost,
      currency: shopItem.currency,
      description: formValues.description,
      explainerImageUrl: formValues.explainerImageUrl,
      imageUrl: formValues.imageUrl,
      name: formValues.name,
      ogCost: formValues.ogCost,
      openedImageUrl: formValues.openedImageUrl,
    };
    await updatePromoShopItem(shopItem.promoId, shopItem.itemId, itemDetails, token);
    setLoading(false);
    handleClose();
  };

  return (
    <form>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          value={formValues.name}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="description" className="form-label">
          Description
        </label>
        <textarea
          className="form-control"
          id="description"
          name="description"
          value={formValues.description}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="ogCost" className="form-label">
          Cost
        </label>
        <input
          type="number"
          className="form-control"
          id="ogCost"
          name="ogCost"
          value={formValues.ogCost}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="cost" className="form-label">
          Discount Cost
        </label>
        <input
          type="number"
          className="form-control"
          id="cost"
          name="cost"
          value={formValues.cost}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="imageUrl" className="form-label">
          Image URL
        </label>
        <input
          type="text"
          className="form-control"
          id="imageUrl"
          name="imageUrl"
          value={formValues.imageUrl}
          onChange={handleChange}
        />
        <img
          src={formValues.imageUrl}
          alt="Shop Item"
          className="mt-2"
          style={{ maxHeight: '200px' }}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="explainerImageUrl" className="form-label">
          Explainer Image URL
        </label>
        <input
          type="text"
          className="form-control"
          id="explainerImageUrl"
          name="explainerImageUrl"
          value={formValues.explainerImageUrl}
          onChange={handleChange}
        />
        <img
          src={formValues.explainerImageUrl}
          alt="Shop Item"
          className="mt-2"
          style={{ maxHeight: '200px' }}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="openedImageUrl" className="form-label">
          Opened Image URL
        </label>
        <input
          type="text"
          className="form-control"
          id="openedImageUrl"
          name="openedImageUrl"
          value={formValues.openedImageUrl}
          onChange={handleChange}
        />
        {formValues.openedImageUrl && (
          <img
            src={formValues.openedImageUrl}
            alt="Shop Item"
            className="mt-2"
            style={{ maxHeight: '200px' }}
          />
        )}
      </div>
      <div className="mb-3">
        <label htmlFor="ungiftable" className="form-label">
          Not Openable<span style={{ color: 'green' }}>*</span>
        </label>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name="ungiftable"
            id="ungiftable"
            value=""
            disabled={true}
            checked={shopItem.ungiftable === true}
          />
        </div>
      </div>

      <div className="mb-3">
        <label htmlFor="ungiftable" className="form-label">
          Max Quantity
        </label>
        <input
          className="form-control"
          type="text"
          name="upperLimit"
          id="upperLimit"
          value={shopItem.upperLimit}
          disabled={true}
        />
      </div>

      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn btn-primary mx-2" onClick={() => handleSubmit(formValues)}>
          {loading ? (
            <>
              <Spinner animation="border" size="sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </>
          ) : (
            'Save Changes'
          )}
        </button>
      </div>
    </form>
  );
};

export default ShopItemDetailsForm;
