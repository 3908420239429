import * as buffer from 'buffer';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';

import { AuthorizationProvider } from './contexts/auth.context';
import { WizardProvider } from './contexts/story.context';
import Web3Provider from './providers/web3.provider';

import LoginPage from './components/login/LoginPage';
import PrivateRoute from './components/PrivateRoute';

import './App.css';
import SelectionPage from './components/selection/SelectionPage';
import Promos from './components/promotions/promos/Promos';
import Promo from './components/promotions/promo/Promo';
import PromoSearch from './components/promotions/search/PromoSearch';
import Campaigns from './components/rff-campaigns/campaigns/Campaigns';
import Campaign from './components/rff-campaigns/campaign/Campaign';
import CampaignSearch from './components/rff-campaigns/search/CampaignSearch';

require('@solana/wallet-adapter-react-ui/styles.css');
window.Buffer = buffer.Buffer;

/**
 * Make sure to wrap the App with
 * ConnectionProvider, WalletProvider, and WalletModalProvider.
 *
 * If you have a lot of Providers already, you can combine them
 * into a single wrapper (i.e. Web3Provider) instead.
 */
export const App = () => {
  return (
    <Web3Provider>
      <AppChild />
    </Web3Provider>
  );
};

function AppChild() {
  const { wallet } = useWallet();
  const { setVisible } = useWalletModal();

  const onRequestConnectWallet = () => {
    setVisible(true);
  };

  // Prompt the user to connect their wallet
  if (!wallet) {
    return <LoginPage onRequestConnectWallet={onRequestConnectWallet} />;
  }

  return (
    <AuthorizationProvider>
      <WizardProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<SelectionPage />} />
            <Route path="/" element={<PrivateRoute />}>
              <Route path="promos" element={<Promos />} />
              <Route path="promos/:promoId" element={<Promo />} />
              <Route path="promos/:promoId/search" element={<PromoSearch />} />
              <Route path="campaigns" element={<Campaigns />} />
              <Route path="campaigns/:campaignId/*" element={<Campaign />} />
              <Route path="campaigns/search" element={<CampaignSearch />} />
            </Route>
          </Routes>
        </Router>
      </WizardProvider>
    </AuthorizationProvider>
  );
}

export default App;
