import React, { useState } from 'react';

const AddShopItemForm = ({ onAdd }) => {
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    ogCost: 0,
    cost: 0,
    upperLimit: 0,
    imageUrl: '',
    explainerImageUrl: '',
    openedImageUrl: '',
    currency: 'FRAGMENTS',
    ungiftable: false,
  });

  const handleChange = event => {
    const { name, value } = event.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]:
        name === 'ogCost' || name === 'cost'
          ? isNaN(parseFloat(value))
            ? 0
            : parseFloat(value)
          : value,
    }));
  };

  const handleChangeUngiftable = () => {
    setFormValues(prevValues => ({
      ...prevValues,
      ungiftable: !formValues.ungiftable,
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    onAdd(formValues);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name<span style={{ color: 'red' }}>*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          value={formValues.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="description" className="form-label">
          Description<span style={{ color: 'red' }}>*</span>
        </label>
        <textarea
          className="form-control"
          id="description"
          name="description"
          value={formValues.description}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="ogCost" className="form-label">
          Cost<span style={{ color: 'red' }}>*</span>
        </label>
        <input
          type="number"
          className="form-control"
          id="ogCost"
          name="ogCost"
          value={formValues.ogCost}
          onChange={handleChange}
          min="0"
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="cost" className="form-label">
          Discount Cost
        </label>
        <input
          type="number"
          className="form-control"
          id="cost"
          name="cost"
          value={formValues.cost}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="upperLimit" className="form-label">
          Max Quantity Per Wallet
        </label>
        <input
          type="number"
          className="form-control"
          id="upperLimit"
          name="upperLimit"
          value={formValues.upperLimit}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="imageUrl" className="form-label">
          Image URL<span style={{ color: 'red' }}>*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="imageUrl"
          name="imageUrl"
          value={formValues.imageUrl}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="explainerImageUrl" className="form-label">
          Explainer Image URL<span style={{ color: 'red' }}>*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="explainerImageUrl"
          name="explainerImageUrl"
          value={formValues.explainerImageUrl}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="openedImageUrl" className="form-label">
          Opened Image URL<span style={{ color: 'red' }}>*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="openedImageUrl"
          name="openedImageUrl"
          value={formValues.openedImageUrl}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="currency" className="form-label">
          Currency<span style={{ color: 'red' }}>*</span>
        </label>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="currency"
            id="fragment"
            value="Fragment"
            checked={formValues.currency === 'FRAGMENTS'}
            onChange={handleChange}
            required
          />
          <label className="form-check-label" htmlFor="fragment">
            Fragment
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="currency"
            id="sol"
            value="SOL"
            checked={formValues.currency === 'SOL'}
            onChange={handleChange}
            required
          />
          <label className="form-check-label" htmlFor="sol">
            SOL
          </label>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="ungiftable" className="form-label">
          Not Openable<span style={{ color: 'green' }}>*</span>
        </label>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name="ungiftable"
            id="ungiftable"
            value=""
            checked={formValues.ungiftable === true}
            onChange={handleChangeUngiftable}
          />
          <label className="form-check-label" htmlFor="ungiftable">
            Not A Box
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button type="submit" className="btn btn-primary">
          ADD
        </button>
      </div>
    </form>
  );
};

export default AddShopItemForm;
