// ExportCSVButton.js

import React from 'react';
import { Button } from 'react-bootstrap';

const ExportCSVButton = ({ data, filename = 'export.csv' }) => {
  const convertArrayToCSV = arr => {
    const header = Object.keys(arr[0]).join(',');
    const rows = arr.map(row => Object.values(row).join(',')).join('\n');
    return `${header}\n${rows}`;
  };

  const handleDownload = () => {
    const csv = convertArrayToCSV(data);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Button variant="success" onClick={handleDownload}>
      Export
    </Button>
  );
};

export default ExportCSVButton;
