const story1 = `
Title: The Stellar Odyssey of Degen Fat Cat: In Pursuit of the Golden Nomad
Style: Dramatic
Tone: Humorous
Prologue:

In a universe where the Degen Fat Cat lineage has extended its reach far beyond the realms of the ordinary, the spirit of adventure and discovery has inspired generations of heroes, each one donning the mantle of Degen Fat Cat. As time passes, the descendants of Whiskers the Golden Nomad have evolved, adapting to their surroundings and embracing the vast expanse of the cosmos.

On a distant desert planet, where mountains rise like silent guardians beneath an evening sky, we find Desert Nomad Fat Cat, a Desert Nomad with a thirst for knowledge and a heart filled with courage. The alien world is a canvas of bold color fields, hyper-detailed illustrations, and vibrant colorscape, painting a picture of a spacesolarpunk paradise.

In a landscape where the flat perspective defies the laws of physics, and the influence of The Stars Art Group imbues every detail with a sense of wonder, Desert Nomad Fat Cat embarks on an epic quest. Guided by an ancient prophecy, he sets out to find the Golden Nomad Degen Fat Cat, a legendary figure said to hold the key to the untapped potential of the Degen Fat Cat lineage.

As our intrepid Desert Nomad journeys through the enigmatic terrain, he will encounter the mysteries and marvels of his extraordinary environment. From oasis cities powered by celestial energy to sentient sandstorms that reveal long-forgotten secrets, Desert Nomad Fat Cat will traverse the alien desert, unveiling the hidden truths of his world.

Along the way, he will forge alliances with otherworldly beings and harness the power of spacesolarpunk technology, pushing the boundaries of his understanding and unlocking new dimensions of his destiny. With each step, he draws closer to the prophetic Golden Nomad Degen Fat Cat, and the revelations that await him.

Will Desert Nomad Fat Cat uncover the secrets of the Golden Nomad and fulfill the prophecy that binds him to his ancestor? What trials and wonders lie hidden within the vast desert, and how will the vibrant colorscape and alien landscape shape his journey? Prepare for an unforgettable adventure, as we follow Desert Nomad Fat Cat on his quest through the cosmos, in pursuit of the legendary Golden Nomad.
`;

const story2 = `
Title: The Cosmic Saga of Desert Nomad Fat Cat: The Desert Nomad's Celestial Quest
Style: Dramatic
Tone: Humorous
Prologue:

Across the vast and ever-changing multiverse, the lineage of the Degen Fat Cats has explored countless realms, each descendant embarking on unique adventures that have shaped the course of their collective destiny. The spirit of exploration runs deep within their blood, fueling their insatiable desire to uncover the mysteries of existence.

In a far-flung corner of the cosmos, a distant desert planet bathed in the hues of twilight beckons the arrival of our protagonist, Desert Nomad Fat Cat. As a Desert Nomad, he has been drawn to this world by the prophecy of the legendary Golden Nomad Degen Fat Cat, a celestial being of untold power and wisdom. It is here that our hero's quest begins, his fate intertwined with that of this enigmatic figure.

The environment of this alien desert is a mesmerizing fusion of natural beauty and otherworldly landscapes. Towering mountains stretch across the horizon, their peaks bathed in the warm glow of the setting sun. The terrain is a canvas painted in bold color fields, where hyper-detailed illustrations and vibrant colorscapes blend seamlessly with the spacesolarpunk aesthetic. This otherworldly panorama is the handiwork of the Stars Art Group, whose distinctive style and flat perspective have brought the desert planet to life.

As Desert Nomad Fat Cat ventures deeper into this celestial desert, he encounters a host of strange and wondrous creatures, each possessing unique abilities and insights that will aid him in his journey. As he traverses this breathtaking world, he uncovers ancient relics and hidden sanctuaries, each step bringing him closer to the prophetic Golden Nomad Degen Fat Cat.

But the path before him is fraught with peril, and the desert planet is home to powerful adversaries who seek to thwart our hero's quest. Will Desert Nomad Fat Cat be able to overcome these obstacles and unlock the secrets of the Golden Nomad Degen Fat Cat? What revelations await him in the heart of this alien world, and how will his journey reshape the legacy of the Degen Fat Cats?

Join Desert Nomad Fat Cat as he embarks on a celestial quest filled with danger, wonder, and discovery. Immerse yourself in the breathtaking landscapes of this distant desert and witness the unfolding of an epic saga that will redefine the boundaries of the Degen Fat Cat lineage.
`;

const story3 = `
Title: The Odyssey of Desert Nomad Fat Cat: In Search of the Golden Nomad
Style: Dramatic
Tone: Humorous
Prologue:

In a universe teeming with infinite possibilities and countless realms, the legacy of the Degen Fat Cats endures. Generation after generation, these intrepid feline explorers have embarked on thrilling adventures, each one leaving their own indelible mark on the tapestry of their family's rich history.

And so, our tale begins with Desert Nomad Fat Cat, a Desert Nomad who hears the call of the sands and the whispers of the winds. Driven by the prophecy of the Golden Nomad Degen Fat Cat, a mythical figure shrouded in mystery and revered for their immense power and knowledge, our hero sets out on a quest that will take him to the farthest reaches of the cosmos.

On a distant desert planet, where the sky is painted with a kaleidoscope of colors as day turns to night, Desert Nomad Fat Cat finds himself standing at the threshold of a grand adventure. Here, the landscape is a breathtaking fusion of alien worlds and spacesolarpunk aesthetics, where bold color fields and hyper-detailed illustrations give way to vibrant colorscapes and awe-inspiring vistas.

As Desert Nomad Fat Cat traverses this mesmerizing terrain, he encounters a menagerie of fantastical creatures, each harboring their own unique abilities and wisdom. Along the way, our hero uncovers hidden sanctuaries, ancient relics, and forgotten civilizations, each clue bringing him closer to the enigmatic Golden Nomad Degen Fat Cat.

But the path to the Golden Nomad is fraught with peril, as nefarious forces lurk in the shadows, eager to thwart our hero's quest. In the face of adversity, will Desert Nomad Fat Cat rise to the challenge and unlock the secrets of the Golden Nomad Degen Fat Cat? What revelations await him on this alien world, and how will his journey reshape the legacy of the Degen Fat Cats?

Embark on a cosmic journey filled with wonder, mystery, and danger as Desert Nomad Fat Cat sets out to uncover the truth behind the prophecy of the Golden Nomad Degen Fat Cat. Immerse yourself in the otherworldly landscapes of this distant desert planet and witness the unfolding of an epic saga that will redefine the boundaries of the Degen Fat Cat lineage.
`;

const story = {
  id: 1,
  pfp: '/images/cats/3768.png',
  name: 'Desert Nomad Fat Cat',
  stories: [story1, story2, story3],
  backgrounds: [
    '/images/backgrounds/nomad_1.jpg',
    '/images/backgrounds/nomad_2.jpg',
    '/images/backgrounds/nomad_3.jpg',
    '/images/backgrounds/nomad_4.jpg',
  ],
};
export default story;
