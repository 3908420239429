import React, { useState } from 'react';
import {
  Modal,
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
} from 'react-bootstrap';

const CreateRiskFreePlayModal = ({ isOpen, onClose, onCreate }) => {
  const [formData, setFormData] = useState({
    reason: '',
    walletIds: [],
    solAmount: '',
    type: 'DEFAULT',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleWalletIdsInput = e => {
    try {
      const walletIds = JSON.parse(e.target.value);
      if (Array.isArray(walletIds)) {
        setFormData({ ...formData, walletIds });
      } else {
        alert('Invalid format. Please provide a valid array of wallet IDs.');
      }
    } catch (error) {
      console.log('Invalid JSON. Please provide a valid JSON array.');
    }
  };
  const handleWalletIdsInputError = e => {
    try {
      const walletIds = JSON.parse(e.target.value);
      if (Array.isArray(walletIds)) {
        console.log('ok');
      } else {
        alert('Invalid format. Please provide a valid array of wallet IDs.');
      }
    } catch (error) {
      alert('Invalid JSON. Please provide a valid JSON array.');
    }
  };

  const handleFileImport = e => {
    const file = e.target.files[0];
    if (file.type === 'application/json') {
      const reader = new FileReader();
      reader.onload = event => {
        try {
          const walletIds = JSON.parse(event.target.result);
          if (Array.isArray(walletIds)) {
            setFormData({ ...formData, walletIds });
          } else {
            alert('Invalid format. Please provide a valid array of wallet IDs.');
          }
        } catch (error) {
          alert('Invalid JSON. Please provide a valid JSON array.');
        }
      };
      reader.readAsText(file);
    } else {
      alert('Invalid file type. Please upload a JSON file.');
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    console.log(formData);
    await onCreate(formData);
    onClose();
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Risk-Free Play</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <FormGroup>
            <FormLabel>Reason</FormLabel>
            <FormControl
              type="text"
              name="reason"
              value={formData.reason}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Sol Amount</FormLabel>
            <FormControl
              type="number"
              name="solAmount"
              value={formData.solAmount}
              onChange={handleChange}
              required
            />
          </FormGroup>
          {/* ... other input fields ... */}
          <FormGroup>
            <FormLabel>Wallet IDs</FormLabel>
            <FormControl
              as="textarea"
              name="walletIds"
              placeholder='Paste wallet IDs as a JSON array, e.g. ["8UNuw6Wv8F47ymuCdZxzN29fK5ETafoyz2ih9YYAozVw", ...]'
              onBlur={handleWalletIdsInputError}
              onChange={handleWalletIdsInput}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Import Wallet IDs from JSON file</FormLabel>
            <InputGroup>
              <FormControl type="file" accept=".json" onChange={handleFileImport} />
            </InputGroup>
          </FormGroup>
          <Form.Group className="mb-3">
            <Form.Label>RFF Type</Form.Label>
            <div>
              <Form.Check
                type="radio"
                id="defaultRadio"
                label="Good For 1 Play (default)"
                name="type"
                value="DEFAULT"
                checked={formData.type === 'DEFAULT'}
                onChange={handleChange}
              />
              <Form.Check
                type="radio"
                id="goodTilWinRadio"
                label="Good Until Win"
                name="type"
                value="GOOD_TIL_WIN"
                checked={formData.type === 'GOOD_TIL_WIN'}
                onChange={handleChange}
              />
            </div>
          </Form.Group>
          {/* ... other input fields ... */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit">
            Create
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateRiskFreePlayModal;
