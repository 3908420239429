import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PromoBreadcrumb = ({ currentName, promoId }) => {
  return (
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/promos/${promoId}` }}>
        Promotional Shop
      </Breadcrumb.Item>
      <Breadcrumb.Item active>{currentName ? currentName : `Purchased Items`}</Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default PromoBreadcrumb;
