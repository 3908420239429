const story1 = `
Title: Moonlit Saga of Samurai Fat Cat: The Samurai's Skybound Path
Style: Narrative
Tone: Epic
Prologue:

In a world where the legacy of the Degen Fat Cats spans across cultures, landscapes, and realms, the spirit of adventure and curiosity has shaped generations of heroes, each bearing the title of Degen Fat Cat. Amidst a tapestry of infinite possibilities, one descendant stands out, blending the ancient ways with an uncharted destiny.

In a time and place where the beauty of tradition meets the ethereal wonders of the sky, we find ourselves in a remote Japanese village nestled in the mountains. The soothing glow of the teal moonlight washes over the landscape, casting the world in an amber hue, and creating an otherworldly atmosphere that hums with the magic of the cosmos.

It is within this tranquil setting that we meet Samurai Fat Cat, a noble samurai who has embraced the ancient path of the warrior. Born with the markings of his ancestors and adorned in intricate armor crafted by the legendary artist, Magali Villeneuve, Samurai Fat Cat is the epitome of grace, strength, and wisdom. His life has been deeply influenced by the teachings of Zen Buddhism, which have shaped his character and guided his blade.

Our tale begins on a moonlit night, as Samurai Fat Cat stands at the edge of the village, gazing at the stars above. The celestial bodies form a mesmerizing cityscape, where the principles of Neo-Geo architecture merge with the wonders of the cosmos, hinting at the existence of a higher realm.

As the whispers of the wind tell tales of skyborne cities and celestial wonders, Samurai Fat Cat feels a powerful calling, a yearning to merge his samurai path with the secrets of the skies. With a heart filled with determination and a spirit guided by the ancient wisdom of Zen, he embarks on an epic journey that will take him from the serene mountaintop village to the heavens above.

In this pseudo-realistic world where the boundaries between earth and sky blur, Samurai Fat Cat will face challenges and revelations that will test his samurai spirit and unveil new dimensions of his destiny. Along the way, he will forge alliances with celestial beings, uncover lost knowledge, and push the limits of his own understanding.

Will Samurai Fat Cat find the answers he seeks among the stars? What trials and wonders await him as he takes to the skies in search of his true purpose? Join our samurai hero as he ventures into the night, guided by the moon's glow and the wisdom of the ancients, in this enchanting tale of discovery, courage, and the endless possibilities of the universe.
`;

const story2 = `
Title: The Celestial Chronicles of Samurai Fat Cat: The Samurai's Moonlit Odyssey

Prologue:

In a multiverse where the Degen Fat Cat lineage has traversed an endless array of realms and dimensions, the spirit of adventure has given rise to a myriad of heroes, each proudly bearing the name Degen Fat Cat. As the generations continue, the descendants of this ancient bloodline forge ahead into unexplored territories, unveiling the wonders that await them beyond the horizon.

In a world where tranquility and harmony embrace the spirit of nature, we find ourselves in a Japanese village nestled within the mountains. As night falls, the silvery moon casts its serene glow upon the landscape, revealing a breathtaking scene painted in hues of teal and amber. The village itself is a blend of traditional architecture and neo-geo cityscapes, showcasing a unique fusion of the past and the future.

It is within this enchanting setting that we meet Samurai Fat Cat, a Samurai Degen Fat Cat who embodies the essence of his Zen Buddhist surroundings. With detailed character design by Magali Villeneuve, he stands as a symbol of discipline and wisdom, ready to embark on a journey that will test the limits of his mind and spirit.

As our Samurai Degen Fat Cat commences his adventure, he ascends into the skies, navigating the majestic mountains and uncovering the secrets of this pseudo-realistic realm. The world around him is an intricate tapestry of natural beauty and innovative design, each element carefully woven together to form a harmonious and captivating environment.

Throughout his quest, Samurai Fat Cat will face trials and revelations that challenge the core of his beliefs. With each new experience, he delves deeper into the heart of the Zen Buddhist teachings, unearthing the hidden wisdom that lies within and forging bonds with the extraordinary beings who inhabit this world.

Will Samurai Fat Cat unravel the mysteries of the moonlit mountains and unlock the true potential of his Samurai spirit? What wonders and dangers await him within the enchanting village, and how will his journey shape the destiny of the Degen Fat Cat lineage? Embrace the tranquility of the night and embark on an unforgettable odyssey, as we follow the exploits of Samurai Fat Cat on his quest to master the art of the Samurai and uncover the secrets of his ethereal world.
`;

const story = {
  id: 2,
  pfp: '/images/cats/12687.png',
  name: 'Samurai Fat Cat',
  stories: [story1, story2],
  backgrounds: [
    '/images/backgrounds/samurai_1.jpg',
    '/images/backgrounds/samurai_2.jpg',
    '/images/backgrounds/samurai_3.jpg',
    '/images/backgrounds/samurai_4.jpg',
  ],
};
export default story;
