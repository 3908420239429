import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Pages } from '../../utils/constants';

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = pageDetail => {
    navigate(pageDetail.path);
  };

  return (
    <div
      className="d-flex flex-column flex-shrink-0 p-3 bg-body-tertiary"
      style={{ width: '280px' }}
    >
      <Link
        to="/"
        className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
        onClick={() => navigate('/')}
      >
        <i className="fa-solid fa-face-laugh-beam me-2" />
        <span className="fs-6">Degen Aristocats</span>
      </Link>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        {Pages.map(pageDetail => (
          <li
            className={`${location.pathname?.startsWith(pageDetail.path) ? 'nav-item' : ''}`}
            key={pageDetail.id}
            onClick={() => handleClick(pageDetail)}
          >
            <Link
              to={pageDetail.path}
              className={`nav-link ${
                location.pathname?.startsWith(pageDetail.path) ? 'active' : 'link-body-emphasis'
              }`}
              aria-current="page"
            >
              <i className={`fa-solid ${pageDetail.icon} me-2`}></i>
              {pageDetail.name}
            </Link>
          </li>
        ))}
      </ul>
      <hr />
      {/* ... rest of the component */}
    </div>
  );
};

export default NavBar;
