import { DateTime } from 'luxon';
import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Table, Button, Form, Badge } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { getPromoShopItemsOfWallet } from '../../../apis/atlantis.api';
import { useAuth } from '../../../contexts/auth.context';
import { formatDate } from '../../../utils/helpers';
import PromoBreadcrumb from './PromoBreadcrumbs';
import ExportCSVButton from './ExportButton';

const PromoSearch = () => {
  const { promoId } = useParams();
  const [walletIdInput, setWalletIdInput] = useState('');
  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  const [items, setItems] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const { auth } = useAuth();
  const authToken = useRef(auth?.idToken);

  useEffect(() => {
    authToken.current = auth?.idToken;
  }, [auth?.idToken]);

  const fetchPurchasedPromoItems = async walletId => {
    const response = await getPromoShopItemsOfWallet(promoId, walletId, authToken.current);
    setItems(
      response.sort((a, b) => DateTime.fromISO(b.modifiedAt) - DateTime.fromISO(a.modifiedAt))
    );
  };

  useEffect(() => {
    const filterItems = () => {
      const searchLower = search.toLowerCase();
      const filtered = items.filter(play =>
        JSON.stringify(play).toLowerCase().includes(searchLower)
      );
      setFilteredItems(filtered);
    };

    filterItems();
  }, [search, items]);

  const getStatusBadge = status => {
    switch (status) {
      case 'OPENED':
        return <Badge bg="success">OPENED</Badge>;
      case 'PURCHASED':
        return <Badge bg="primary">PURCHASED</Badge>;
    }
  };

  // const renderStatusBadge = expireAt => {
  //   const isExpired = DateTime.fromISO(expireAt) < DateTime.utc();

  //   return <Badge bg={isExpired ? 'danger' : 'success'}>{isExpired ? 'Expired' : 'Active'}</Badge>;
  // };

  return (
    <Container className="pt-3" style={{ overflow: 'scroll' }}>
      <PromoBreadcrumb currentName={`Searching...`} promoId={promoId} />
      {
        <>
          <Row className="mb-3">
            <Col>
              <Form.Control
                type="text"
                placeholder="Enter WalletId"
                value={walletIdInput}
                onChange={e => setWalletIdInput(e.target.value)}
              />
            </Col>
            <Col className="col-auto">
              <Button onClick={() => fetchPurchasedPromoItems(walletIdInput)}>
                <i className="fa-solid fa-search" /> Search
              </Button>
            </Col>
            <Col className="col-auto">
              <Button variant="secondary" onClick={() => setShowFilter(!showFilter)}>
                <i className="fa-solid fa-filter" />
              </Button>
            </Col>
            <Col className="col-auto">
              <ExportCSVButton data={filteredItems} />
            </Col>
          </Row>
          {showFilter && (
            <Row className="my-4">
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Filter"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
              </Col>
            </Row>
          )}
        </>
      }
      <Row>
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Item</th>
                <th>Name</th>
                <th>Cost</th>
                <th>Status</th>
                <th>Updated</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.map(item => (
                <tr key={item.id}>
                  <td>{item.itemId.slice(0, 4)}</td>
                  <td>
                    <img
                      src={item.imageUrl}
                      alt={item.name}
                      style={{
                        width: '100%',
                        maxWidth: '100px',
                        maxHeight: '100px',
                        objectFit: 'contain',
                      }}
                      className="mb-2 mb-md-0"
                    />
                  </td>
                  <td>{item.name}</td>
                  <td>{item.usedFragments} pts</td>
                  <td>{getStatusBadge(item.status)}</td>
                  <td>{formatDate(item.modifiedAt, DateTime.DATE_SHORT)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default PromoSearch;
